export const pricing = {
  personal: [
    {
      title: "Basic",
      price: 499.99,
      features: [
        "500GB storage",
        "File recovery",
        "Password protection",
        "Basic sharing options",
      ],
    },
    {
      title: "Plus",
      price: 999.99,
      popular: true,
      features: [
        "2TB storage",
        "File recovery and version history",
        "Password protection",
        "Advanced sharing with family access",
        "24/7 customer support",
      ],
    },
    {
      title: "Premium",
      price: 1499.99,
      features: [
        "5TB storage",
        "File recovery, version history, and encrypted backup",
        "Password protection and watermarking",
        "Priority sharing and collaboration tools",
        "Enhanced privacy settings",
      ],
    },
  ],
  business: [
    {
      title: "Starter",
      price: 1999.99,
      features: [
        "1TB team storage",
        "File recovery",
        "Password protection",
        "Basic sharing with teams",
      ],
    },
    {
      title: "Pro",
      price: 4999.99,
      popular: true,
      features: [
        "5TB team storage",
        "File recovery and audit trails",
        "Password protection and watermarking",
        "Advanced sharing with permissions",
        "Priority customer support",
      ],
    },
    {
      title: "Enterprise",
      price: 9999.99,
      features: [
        "Unlimited storage",
        "Comprehensive file recovery and audit logs",
        "Password protection, watermarking, and viewer history",
        "Advanced sharing with user management",
        "Real-time collaboration and project management tools",
        "Dedicated account manager and custom integrations",
      ],
    },
  ],
};
